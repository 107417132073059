import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../App.css";
import Navbarbg from "../images/navbarbg.png";
import Navbarbg2 from "../images/responsivenav.png";
import Logo from "../images/black_logo.png";
import WhiteLogo from "../images/white_logo.png";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ theme, setIsMenuOpen, isMenuOpen, activeSection }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isApplyMenu, setIsApplyMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    if (location.pathname.includes("/apply-for-job")) {
      setIsApplyMenu((prev) => !prev);
    } else {
      setIsMenuOpen((prev) => !prev);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const scrollToSectionById = (id) => {
    if (location.pathname.includes("/apply-for-job")) {
      navigate("/", { replace: true });
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    } else {
      scrollToElement(id);
    }
  };

  const scrollToElement = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      const isSmallScreen = window.innerWidth < 900;
      const baseFontSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );

      let offset;
      if (
        !isSmallScreen &&
        (id === "applyjob" || id === "contact us" || id === "home")
      ) {
        offset = 8 * baseFontSize;
      } else if (
        isSmallScreen &&
        (id === "applyjob" || id === "home" || id === "contact us")
      ) {
        offset = 8 * baseFontSize;
      } else {
        offset = (isSmallScreen ? 2 : 2) * baseFontSize;
      }

      window.scrollTo({
        top: sectionTop - offset,
        behavior: "smooth",
      });
    }
  };

  const handleButtonClick = (section) => {
    if (location.pathname.includes("/apply-for-job")) {
      navigate("/", { replace: true });
      setTimeout(() => {
        if (section === "contact") {
          scrollToSectionById("contact us");
        } else {
          scrollToSectionById(section);
        }
      }, 500);
    } else {
      if (section === "contact") {
        scrollToSectionById("contact us");
      } else {
        scrollToSectionById(section);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > window.innerHeight * 0.01);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isApplyJobPath = location.pathname.includes("/apply-for-job");

  return (
    <div className="flex items-center justify-center">
      <nav
        className={`w-[90vw] fixed z-50 transition-all ${
          isSticky ? "top-0" : "top-[6.5vh]"
        }`}
        style={{
          backgroundImage:
            isApplyJobPath || theme === "theme1"
              ? `url(${Navbarbg})`
              : `url(${Navbarbg2})`,
          color: theme === "theme1" ? "black" : "",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          position: isSticky ? "fixed" : "absolute",
          // boxShadow: "0px -3px 10px -5px rgba(0, 0, 0, 0.5)",
          borderRadius: "1rem",
        }}
      >
        <div className="flex justify-between items-center p-3 relative bottom-2 cursor-pointer">
          <div
            className="flex items-center"
            onClick={() => {
              handleButtonClick("home");
            }}
          >
            <img
              src={isApplyJobPath || theme === "theme1" ? Logo : WhiteLogo}
              alt="Logo here"
              className="w-56"
            />
          </div>

          <div className="hidden md:flex items-center xl:gap-10 gap-4 text-center">
            {["home", "service", "career", "contact"].map((section) => (
              <button key={section} onClick={() => handleButtonClick(section)}>
                {activeSection === section && (
                  <span
                    className={`mr-2 font-extrabold text-2xl ${
                      isApplyJobPath || theme === "theme1"
                        ? "gradient-icon relative top-[0.1rem]"
                        : "text-white-100 relative top-[0.1rem]"
                    }`}
                  >
                    •
                  </span>
                )}
                <span
                  className={`font-medium text-lg ${
                    activeSection === section
                      ? isApplyJobPath || theme === "theme1"
                        ? "gradient-icon relative bottom-[0.18rem]"
                        : "text-white-100 relative bottom-[0.18rem]"
                      : isApplyJobPath || theme === "theme1"
                      ? "text-black-100"
                      : "text-slate-400"
                  }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </span>
              </button>
            ))}
            <button
              onClick={() => {
                scrollToSectionById("applyjob");
              }}
              className={`${
                isApplyJobPath || theme === "theme1"
                  ? "bg-custom-gradient text-white-100"
                  : "bg-white-100 text-primary-100"
              } lg:px-4 px-3 py-2 rounded text-md font-medium`}
            >
              Apply For Job
            </button>
          </div>

          <div className="md:hidden relative">
            <button
              onClick={toggleMenu}
              className={`${
                isApplyJobPath || theme === "theme1"
                  ? "text-gray-500"
                  : "text-white-100"
              } text-2xl`}
            >
              <FaBars />
            </button>
          </div>
        </div>

        <div
          className={`fixed top-0 left-0 h-[100vh] bg-white-100 shadow-lg z-20 w-64 transform ${
            isMenuOpen || isApplyMenu ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out`}
        >
          <div className="flex justify-end p-4">
            <button onClick={closeMenu} className="text-gray-500 text-2xl">
              <FaTimes />
            </button>
          </div>
          <div className="flex flex-col gap-6 p-6 text-lg text-center">
            {["home", "service", "career", "contact"].map((section) => (
              <div
                key={section}
                onClick={() => {
                  handleButtonClick(section);
                  closeMenu();
                }}
                className={`cursor-pointer font-medium ${
                  activeSection === section ? "gradient-icon" : "text-black-100"
                }`}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </div>
            ))}
            <div
              onClick={() => {
                scrollToSectionById("applyjob");
                closeMenu();
              }}
              className={`bg-custom-gradient text-white-100 text-center w-full h-[2.5rem] rounded-md flex justify-center items-center cursor-pointer`}
            >
              Apply For Job
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-10"
            onClick={closeMenu}
          />
        )}
      </nav>
    </div>
  );
};

export default Navbar;
