import React from "react";
import Logo from "../images/white_logo.png";
import WhatsApp from "../images/whatsapp.png";
import Map from "../images/map.png";
import Email from "../images/email.png";
import linkdin from "../images/linkedin.png";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementTop = element.getBoundingClientRect().top + window.scrollY;
      const isSmallScreen = window.innerWidth < 800;
      const baseFontSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );

      let offset;
      if (id === "applyjob" || id === "contact us" || id === "home") {
        offset = 8 * baseFontSize;
      } else {
        offset = (isSmallScreen ? 2 : 2) * baseFontSize;
      }

      window.scrollTo({
        top: elementTop - offset,
        behavior: "smooth",
      });
    } else {
      console.error(`Element with ID '${id}' not found.`);
    }
  };

  const handleNavigation = (id) => {
    if (location.pathname.startsWith("/apply-for-job")) {
      navigate("/");
      setTimeout(() => handleScroll(id), 100);
    } else {
      handleScroll(id);
    }
  };

  return (
    <div className="text-white-100 min-h-[32vh] flex flex-col gap-4 py-4 items-center justify-center  ">
      <img
        src={Logo}
        alt="Logo"
        className="cursor-pointer w-56"
        onClick={(e) => {
          e.preventDefault();
          handleNavigation("home");
        }}
      />
      <div className="flex gap-[2rem]">
        <a
          href="#home"
          className="text-white-100 hover:text-gray-300 text-sm md:text-base"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation("home");
          }}
        >
          Home
        </a>
        <a
          href="#service"
          className="text-white-100 hover:text-gray-300 text-sm md:text-base"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation("service");
          }}
        >
          Services
        </a>
        <a
          href="#career"
          className="text-white-100 hover:text-gray-300 text-sm md:text-base"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation("contact us");
          }}
        >
          Contact Us
        </a>
        <a
          href="#contact"
          className="text-white-100 hover:text-gray-300 text-sm md:text-base"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation("applyjob");
          }}
        >
          Apply for job
        </a>
      </div>
      <div className="flex gap-4">
        <Link
          to=""
          onClick={() => window.open("https://wa.me/917600273027", "_blank")}
        >
          <div className="contact-border h-[3rem] w-[3rem] flex items-center justify-center">
            <img src={WhatsApp} alt="WhatsApp" />
          </div>
        </Link>
        <Link
          to=""
          onClick={() =>
            window.open("https://maps.app.goo.gl/UWMVb2tnbYZK6SBS7", "_blank")
          }
        >
          <div className="contact-border h-[3rem] w-[3rem] flex items-center justify-center">
            <img src={Map} alt="Map" />
          </div>
        </Link>
        <Link
          to=""
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/ashirwad-infotech/",
              "_blank"
            )
          }
        >
          <div className="contact-border h-[3rem] w-[3rem] flex items-center justify-center">
            <img src={linkdin} alt="LinkedIn" />
          </div>
        </Link>
        <Link
          to=""
          onClick={() =>
            window.open("mailto:info@ashirwadinfotech.com", "_blank")
          }
        >
          <div className="contact-border h-[3rem] w-[3rem] flex items-center justify-center">
            <img src={Email} alt="Email" />
          </div>
        </Link>
      </div>
      <hr className="w-full border-gray-500" />
      <p className="text-center">
        Copyright © 2024 Ashirwad Infotech. All rights reserved
      </p>
    </div>
  );
};

export default Footer;
