import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./component/home/Home";
import ApplyForJob from "./component/home/ApplyForJob";
import MainLayout from "./component/global/MainLayout"

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout/>}>
        <Route index element={<Home />} />
        <Route path="/apply-for-job/:id" element={<ApplyForJob />} />
      </Route>
    </Routes>
  );
};

export default App;
