import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Earth from "../../images/earth.png";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import axios from "axios";

const OurFamily = () => {
  const navigate = useNavigate();
  const [expandedJob, setExpandedJob] = useState(null);
  const [position, setposition] = useState("");

  const handleToggle = (jobId) => {
    setExpandedJob((prevJobId) => (prevJobId === jobId ? null : jobId));
  };

  const handleApply = (jobId) => {
    navigate(`apply-for-job/${jobId}`);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_JOB_DETAIL_URL + "jobs/"
        );
        setposition(response.data);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, []);

  return (
    <>
      {position && Array.isArray(position) && position.length > 0 ? (
        <div className="flex flex-col gap-5 h-auto" id="applyjob">
          <div className="text-4xl xl:text-5xl font-bold text-gray-100">
            <span className="bg-primary-300 rounded-full px-10 pr-1">L</span>
            et’s join our Family
          </div>
          <div className="xl:flex-row md:flex-row flex flex-col gap-5 justify-center items-center">
            <img src={Earth} alt="earth" className="xl:w-1/2 md:w-1/2 h-auto" />
            <div className="xl:w-1/2 md:w-1/2 w-full flex flex-col gap-3">
              {position && position.length > 0
                ? position.map((job) => (
                    <div
                      key={job.id}
                      className="border-1 border-primary-100 rounded-lg xl:p-3 p-3 bg-primary-200 flex flex-col gap-2 min-h-[4rem] justify-center cursor-pointer"
                      onClick={() => handleToggle(job.id)}
                    >
                      <div className="flex justify-between items-center">
                        <div
                          className={`font-semibold md:text-xl xl:text-xl text-md ${
                            expandedJob === job.id
                              ? "text-primary-100"
                              : "text-black"
                          }`}
                        >
                          {job.position_name}
                        </div>
                        {expandedJob === job.id ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )}
                      </div>
                      {expandedJob === job.id && (
                        <div className="flex flex-col gap-3">
                          <p>{job.description}</p>
                          <div className="flex">
                            <div className="flex w-1/2 items-center">
                              <p className="font-medium">Position:</p>
                              <p className="text-sm">{job.n_position}</p>
                            </div>
                            <div className="flex w-1/2">
                              <p className="font-medium">Experience:</p>
                              <p className="text-sm">{job.experience}</p>
                            </div>
                          </div>
                          <div className="flex">
                            <div className="w-1/2">
                              <p className="font-medium">Salary:</p>
                              <ul className="pl-5">
                                <li className="text-sm list-disc">
                                  As per industry standards
                                </li>
                              </ul>
                            </div>
                            <div className="w-1/2">
                              <p className="font-medium">Working Hours:</p>
                              <ul className="pl-5">
                                <li className="text-sm list-disc">
                                  Flexible Timing (Monday–Friday)
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="flex">
                            <div className="w-1/2">
                              <button
                                onClick={() => handleApply(job.id)}
                                className="text-white-100 bg-custom-gradient rounded-md h-[2.3rem] px-3"
                              >
                                Apply for Job
                              </button>
                            </div>
                            <p className="font-medium w-1/2">{job.location}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OurFamily;
