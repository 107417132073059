import Html from "../component/images/t13.png";
import Flutter from "../component/images/t6.png";
import Figma from "../component/images/t5.png";
import Python from "../component/images/t8.png";
import { IoLogoCss3 } from "react-icons/io";
import { FaJsSquare } from "react-icons/fa";
import { FaReact } from "react-icons/fa6";
import { DiAndroid } from "react-icons/di";
import { FaApple } from "react-icons/fa6";
import { SiAdobephotoshop } from "react-icons/si";
import { SiAdobeillustrator } from "react-icons/si";
import { SiAdobexd } from "react-icons/si";
import { FaNodeJs } from "react-icons/fa6";
import { TbBrandDjango } from "react-icons/tb";
import { SiFlask } from "react-icons/si";
import { FaHtml5 } from "react-icons/fa";
import { RiFlutterFill } from "react-icons/ri";
import { FiFigma } from "react-icons/fi";
import { FaPython } from "react-icons/fa6";
import Css from "../component/images/Css.png";
import Js from "../component/images/t3.png";
import React_icon from "../component/images/t9.png";
import Android from "../component/images/t1.png";
import Apple from "../component/images/t11.png";
import Ai from "../component/images/t4.png";
import Ps from "../component/images/t2.png";
import Xd from "../component/images/xd.png";
import Node from "../component/images/node.png";
import Flask from "../component/images/flask.png";
import Dj from "../component/images/t12.png";
import ex1 from "../component/images/ex1.png";
import ex2 from "../component/images/ex2.png";
import ex3 from "../component/images/ex3.png";
import ex4 from "../component/images/ex4.png";
// process imports
import pro1 from "../component/images/process1.png";
import pro2 from "../component/images/process2.png";
import pro3 from "../component/images/process3.png";
import pro4 from "../component/images/process4.png";
import procol1 from "../component/images/processcol1.png";
import procol2 from "../component/images/processcol2.png";
import procol3 from "../component/images/processcol3.png";
import procol4 from "../component/images/processcol4.png";
// career data
import ac1 from "../component/images/ac1.png";
import ac2 from "../component/images/ac2.png";
import ac3 from "../component/images/ac3.png";
import ac4 from "../component/images/ac4.png";
import ac5 from "../component/images/ac5.png";
import ac6 from "../component/images/ac6.png";
import ac7 from "../component/images/ac7.png";
import ac8 from "../component/images/ac8.png";
import ac9 from "../component/images/ac9.png";
import ac10 from "../component/images/ac10.png";

export const ExpertiseData = [
  {
    id: 1,
    title: "Web Development",
    Description:
      "In today's digital world, a strong online presence is essential for businesses to succeed. Your website serves as the virtual face of your business, representing your brand, products, and services to the online audience. At Ashirwad Infotech, we offer professional web development services that help you create a powerful online presence and achieve your business goals.",
    icon: ex1,
    icon1: <FaHtml5 />,
    icon2: <IoLogoCss3 />,
    icon3: <FaJsSquare />,
    icon4: <FaReact />,
    hicon1: Html,
    hicon2: Css,
    hicon3: Js,
    hicon4: React_icon,
  },
  {
    id: 2,
    title: "Mobile App Development",
    Description:
      "As mobile devices continue to dominate the digital landscape, having a mobile app for your business is no longer a luxury, but a necessity. A well-designed mobile app can help you connect with your customers, enhance their user experience, and drive business growth. At Ashirwad Infotech, we offer top-notch mobile app development services that are tailored to meet your unique business requirements.",

    icon: ex2,
    icon1: <RiFlutterFill />,
    icon2: <DiAndroid />,
    icon3: <FaReact />,
    icon4: <FaApple />,
    hicon1: Flutter,
    hicon2: Android,
    hicon3: React_icon,
    hicon4: Apple,
  },
  {
    id: 3,
    title: "UI/UX Designing Development",
    Description:
      "At Ashirwad Infotech, we are dedicated to providing engaging UI/UX development services that create seamless and enjoyable user experiences on your website.With our user-centric approach, customized design solutions, responsive design, visual appeal, and branding, usability and accessibility focus, interaction design expertise, and testing and iteration process, we can help you create a website that captivates your audience and drives business success. Contact us today to discuss your UI/UX development needs.",
    icon: ex3,
    icon1: <FiFigma />,
    icon2: <SiAdobeillustrator />,
    icon3: <SiAdobephotoshop />,
    icon4: <SiAdobexd />,
    hicon1: Figma,
    hicon2: Ai,
    hicon3: Ps,
    hicon4: Xd,
  },
  {
    id: 4,
    title: "Backend Development",
    Description:
      "Ashirwad Infotech is committed to providing reliable backend development services that empower your website with robust functionality, performance, security, and scalability. With our expertise, customized solutions, focus on security and data privacy, integration capabilities, testing and quality assurance, and ongoing support, we can help you create a backend that powers your website's success.Contact us today to discuss your backend development needs and ensure your website operates at its best.",
    icon: ex4,
    icon1: <FaPython />,
    icon2: <SiFlask />,
    icon3: <FaNodeJs />,
    icon4: <TbBrandDjango />,
    hicon1: Python,
    hicon2: Flask,
    hicon3: Node,
    hicon4: Dj,
  },
];

export const ProcessData = [
  {
    id: 1,
    title: "Ideation",
    preimg: pro1,
    imgcol: procol1,
    description:
      "We shape brands through exploration and research, challenging assumptions at every turn to foster innovation.",
  },
  {
    id: 2,
    title: "Design",
    preimg: pro2,
    imgcol: procol2,
    description:
      "Once a 360 degree research is completed, We list down the strategies as pre-planning assures us build a robust development procedure.",
  },
  {
    id: 3,
    title: "Build",
    preimg: pro3,
    imgcol: procol3,
    description:
      "In this phase, our experts start building on your requirements and are committed to fulfill it to your expectations.",
  },
  {
    id: 4,
    title: "Deliver",
    preimg: pro4,
    imgcol: procol4,
    description:
      "With a green signal from our quality assurance experts, your product is ready to launch in the market delivering a peak performance.",
  },
];

// career data
export const CareerData = [
  { id: 1, name: "Fun Activity", img: ac1 },
  { id: 2, name: "Career Growth", img: ac2 },
  { id: 3, name: "Festival Celebration", img: ac3 },
  { id: 4, name: "Paid Leave", img: ac4 },
  { id: 5, name: "Empower learning", img: ac5 },
  { id: 6, name: "Mission", img: ac6 },
  { id: 7, name: "Performance Rewards", img: ac7 },
  { id: 8, name: "Flexible Time", img: ac8 },
  { id: 9, name: "Work Environment", img: ac9 },
  { id: 10, name: "Team Outing", img: ac10 },
];
