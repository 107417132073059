import React, { forwardRef } from "react";
import Expertise from "./Expertise";
import Process from "./Process";
import Technology from "./Technology";

const Servicesindex = forwardRef(({ servicesRef }) => {
  return (
    <div className="bg-white-100 xl:rounded-[5rem] md:rounded-[5rem] rounded-[3rem] shadow-md xl:py-24 md:py-24 p-10 py-20 flex flex-col items-center  ">
      <div className="flex flex-col justify-center items-center gap-24">
        {/* <div className='2xl:w-[80vw] w-[90vw] flex flex-col gap-24'> */}
        <Expertise servicesRef={servicesRef} />
        <Process servicesRef={servicesRef} />
        <Technology servicesRef={servicesRef} />
      </div>
    </div>
  );
});

export default Servicesindex;
