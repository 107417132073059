import React, { useState } from "react";
import { ExpertiseData } from "../../../data/Mockdata";

const Expertise = ({ servicesRef }) => {
  const [hoveredIcon, setHoveredIcon] = useState({});

  const handleMouseEnter = (id, iconNumber) => {
    setHoveredIcon({ id, iconNumber });
  };

  const handleMouseLeave = () => {
    setHoveredIcon({});
  };

  return (
    <div
      className="max-w-screen-2xl flex flex-col gap-4 md:px-6 px-3"
      ref={servicesRef}
    >
      <div className="flex flex-col gap-3 xl:flex-row md:flex-row justify-between items-start">
        <div className="font-bold text-gray-100 md:w-1/3 text-4xl text-nowrap xl:text-5xl  ">
          <span className="bg-primary-300 rounded-full xl:pl-6 pl-3 pr-1">
            O
          </span>
          ur Expertise In
        </div>
        .
        <p className="text-gray-600 text-base leading-relaxed md:w-2/3">
          Our Mobile developers are aiming to have top notch product solution
          with Flutter , Kotlin , SWIFT and React Native. Our expert frontend
          developers with an eye for detail and passion for web designing by
          having skills in HTML, CSS, Javascript, AngularJS, ReactJS ,NextJS ,
          JQuery etc. Our backend developers are available for system
          development of your project and use tools like NodeJS , Python,
          Laravel, Codeignitor, C++ , Java etc.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:p-4 xl:p-4">
        {ExpertiseData.map((item) => (
          <div
            key={item.id}
            className="rounded-xl flex flex-col gap-2"
            style={{
              position: "relative",
              border: "1px dashed white",
              background: "linear-gradient(to bottom, #0076B6, #F8FDFF)",
            }}
          >
            <div className="bg-primary-200 h-full p-4 rounded-md border-t border-dashed border-primary-100">
              <div className="flex gap-[0.8rem] items-center">
                <div className="bg-custom-gradient md:h-12 md:w-12 w-10 h-10 items-center flex justify-center rounded-full">
                  <img src={item.icon} alt="title" />
                </div>
                <div className="text-xl font-semibold">{item.title}</div>
              </div>
              <div className="flex justify-between md:gap-10 xl:gap-10 gap-4 md:p-2 xl:p-2 relative left-1 md:left-0">
                <div className="flex flex-col gap-3 text-center relative top-2">
                  <div
                    onMouseEnter={() => handleMouseEnter(item.id, 1)}
                    onMouseLeave={handleMouseLeave}
                    className="text-[1.8rem] text-gray-200"
                  >
                    {hoveredIcon.id === item.id &&
                    hoveredIcon.iconNumber === 1 ? (
                      <img src={item.hicon1} alt="icon" />
                    ) : (
                      item.icon1
                    )}
                  </div>
                  <div
                    onMouseEnter={() => handleMouseEnter(item.id, 2)}
                    onMouseLeave={handleMouseLeave}
                    className="text-[1.8rem] text-gray-200"
                  >
                    {hoveredIcon.id === item.id &&
                    hoveredIcon.iconNumber === 2 ? (
                      <img src={item.hicon2} alt="icon" />
                    ) : (
                      item.icon2
                    )}
                  </div>
                  <div
                    onMouseEnter={() => handleMouseEnter(item.id, 3)}
                    onMouseLeave={handleMouseLeave}
                    className="text-[1.8rem] text-gray-200"
                  >
                    {hoveredIcon.id === item.id &&
                    hoveredIcon.iconNumber === 3 ? (
                      <img src={item.hicon3} alt="icon" />
                    ) : (
                      item.icon3
                    )}
                  </div>
                  <div
                    onMouseEnter={() => handleMouseEnter(item.id, 4)}
                    onMouseLeave={handleMouseLeave}
                    className="text-[1.8rem] text-gray-200"
                  >
                    {hoveredIcon.id === item.id &&
                    hoveredIcon.iconNumber === 4 ? (
                      <img src={item.hicon4} alt="icon" />
                    ) : (
                      item.icon4
                    )}
                  </div>
                </div>
                <p className="text-gray-100">{item.Description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Expertise;
