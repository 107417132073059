import React from "react";
import Mail from "../images/infomail.png";
import Loc from "../images/infoloc.png";
import Call from "../images/infocall.png";
import { Link } from "react-router-dom";

const Info = () => {
  return (
    <>
      <div className=" bg-white-100 h-[6.5rem] z-40 py-2  ">
        <div className="hidden md:block">
          <div className="flex flex-wrap gap-3 justify-center items-center text-xs md:py-3 xl:py-3 py-2 font-medium">
            <div className="flex gap-20">
              <Link
                to=""
                onClick={() =>
                  window.open("mailto:info@ashirwadinfotech.com", "_blank")
                }
                className="text-gray-700 sm:text-base"
              >
                <div className="flex items-center gap-2">
                  <img
                    src={Mail}
                    alt="info"
                    className="gradient-icon text-sm"
                  />
                  <span className="text-gray-700 sm:text-base">
                    info@ashirwadinfotech.com
                  </span>
                </div>
              </Link>
              <Link
                to=""
                onClick={() =>
                  window.open("https://wa.me/917600273027", "_blank")
                }
              >
                <div className="flex items-center gap-2">
                  <img
                    src={Call}
                    alt="info"
                    className="gradient-icon text-sm"
                  />
                  <span className="text-gray-700 sm:text-base">
                    +91 76002 73027
                  </span>
                </div>
              </Link>

              <Link
                to=" "
                onClick={() =>
                  window.open("https://g.page/Laxmi-Enclave-2?share", "_blank")
                }
              >
                <div className="flex items-center gap-2">
                  <img src={Loc} alt="info" className="gradient-icon text-sm" />
                  <span className="text-gray-700 sm:text-base cursor-pointer">
                    Katargam, Surat.
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
