import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ProcessData } from "../../../data/Mockdata";

const Process = ({ servicesRef }) => {
  const [activeSteps, setActiveSteps] = useState([1]);
  const [activeLines, setActiveLines] = useState([]);
  const [hoveredStep, setHoveredStep] = useState(null);
  const [highlightedLines, setHighlightedLines] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleStepClick = (stepId) => {
    setActiveSteps((prev) => {
      const newActiveSteps = [...prev];
      if (!newActiveSteps.includes(stepId)) {
        for (let i = newActiveSteps.length + 1; i <= stepId; i++) {
          newActiveSteps.push(i);
        }
      }
      return newActiveSteps;
    });

    setActiveLines((prev) => {
      const newActiveLines = [...prev];
      if (!newActiveLines.includes(stepId - 1)) {
        for (let i = newActiveLines.length + 1; i < stepId; i++) {
          newActiveLines.push(i);
        }
      }
      return newActiveLines;
    });

    setHoveredStep(null);
    setHighlightedLines([]);
  };

  const handleStepHover = (stepId) => {
    setHoveredStep(stepId);
    const linesToHighlight = [];
    for (let i = 1; i < stepId; i++) {
      linesToHighlight.push(i);
    }
    setHighlightedLines(linesToHighlight);
  };

  const checkScreenSize = () => {
    setIsSmallScreen(window.matchMedia("(max-width: 800px)").matches);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className="flex bg-primary-200 w-[99vw] justify-center items-center" ref={servicesRef}>
      <div className="max-w-screen-2xl py-20 flex flex-col gap-12 md:px-6 px-3">
      <h2 className="text-4xl xl:text-5xl font-bold text-gray-100 ">
        <span className="bg-primary-300 rounded-full pl-6">W</span>
        e simplify the software development process
      </h2>

      {/* Render the horizontal timeline if screen width is more than 800px */}
      {!isSmallScreen && (
        <div className="relative grid grid-cols-1 md:grid-cols-4 gap-8">
          {ProcessData.map((step, index) => {
            const isActive = activeSteps.includes(step.id);
            const isLineActive = activeLines.includes(step.id) || highlightedLines.includes(step.id);
            const isHovered = hoveredStep === step.id;
            const isPrevStepHovered = hoveredStep && step.id < hoveredStep;

            return (
              <motion.div
                key={step.id}
                className="flex flex-col md:items-start text-start cursor-pointer relative"
                onClick={() => handleStepClick(step.id)}
                onMouseEnter={() => handleStepHover(step.id)}
                onMouseLeave={() => {
                  setHoveredStep(null);
                  setHighlightedLines([]);
                }}
              >
                <div className="relative rounded-full p-3 mb-4 right-8 z-10 transition duration-300 overflow-hidden">
                  <img
                    src={isActive || isHovered || isPrevStepHovered ? step.imgcol : step.preimg}
                    alt={step.title}
                    className="w-16 h-20 rounded-full relative bottom-4"
                  />
                </div>

                <h3 className="text-xl font-[500] mb-2">{step.title}</h3>
                <p>{step.description}</p>

                {index < ProcessData.length - 1 && (
                  <motion.div
                    key={`line-${index}`}
                    className="hidden md:block absolute left-1/2 transform -translate-x-1/2"
                    animate={{
                      borderColor: isLineActive ? "#022E7E" : "#E0E0E0",
                    }}
                    initial={{ borderColor: "#E0E0E0" }}
                    transition={{ duration: 0.5 }}
                    style={{
                      top: "28px",
                      width: "110%",
                      height: "2px",
                      marginLeft: "3rem",
                      borderTopStyle: "dotted",
                      borderTopWidth: "2px",
                    }}
                  />
                )}
              </motion.div>
            );
          })}
        </div>
      )}

      {/* Render the vertical timeline if screen width is 800px or less */}
      {isSmallScreen && (
        <div className="relative flex flex-col gap-8">
          {ProcessData.map((step, index) => (
              <div
                key={step.id}
                className="flex items-start text-start"
              >
                  <img
                    src={step.imgcol}
                    alt={step.title}
                    className=" w-[5rem] h-[6rem] relative bottom-3"
                  />
                <div className="">
                  <h3 className="text-xl font-semibold">{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              </div>
          )
          )}
        </div>
      )}
    </div>
    </div>
  );
};

export default Process;
