import React, { useRef, useEffect, useState } from "react";
import Navbar from "../global/Navbar";
import Hero from "./Hero";
import Servicesindex from "./services/Servicesindex";
import Career from "./Career";
import ContactIndex from "./contact/Contact_index";
import { useLocation } from "react-router-dom";

const Home = () => {
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const careerRef = useRef(null);
  const contactRef = useRef(null);
  const inTouchRef = useRef(null);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [theme, setTheme] = useState('theme1');
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('career') || path.includes('home')) {
      setTheme('theme1');
    } else if (path.includes('contact us') || path.includes('services')) {
      setTheme('theme2');
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScrollEvent = () => {
      const sections = {
        home: homeRef,
        service: servicesRef,
        career: careerRef,
        contact: contactRef,
      };
      let currentSection = 'home';
      Object.keys(sections).forEach((section) => {
      if (sections[section].current) {
        const rect = sections[section].current.getBoundingClientRect();
        const isInView = rect.top <= 48 && rect.bottom >= 0;

        if (isInView) {
          currentSection = section;
        }
      }
    });
    if (currentSection === 'home' || currentSection === 'career') {
      setTheme('theme1');
    } else {
      setTheme('theme2');
    }
    setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, [homeRef, servicesRef, careerRef, contactRef, setTheme]);

  return (
    <div>
      <div className="relative xl:bottom-[6.5rem] md:bottom-[6.5rem] bottom-[6.5rem] ">
      <Navbar
        theme={theme}
        setIsMenuOpen={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
        activeSection={activeSection}
      />
      </div>
      <section id="home" ref={homeRef}>
        <Hero />
      </section>
      <section id="service" ref={servicesRef}>
        <Servicesindex servicesRef={servicesRef}/>
      </section>
      <section id="career" ref={careerRef}>
        <Career ref={careerRef}/>
      </section>
      <section id="contact" ref={contactRef}>
        <ContactIndex inTouchRef={inTouchRef} />
      </section>
    </div>
  );
};

export default Home;
