import React from "react";
import herobg from "../images/aibg.png";
import "../../Global.css";
import Resbg from "../images/minibg.png";

const Hero = () => {
  return (
    <div
      className="flex flex-col h-[90vh] bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${herobg})` }}
    >
      <h1 className="xl:text-5xl md:text-5xl text-4xl text-white md:leading-loose xl:leading-relaxed md:px-0 xl:px-0 px-3 text-center relative top-[20vh] w-full  ">
        Let's Build Online Success <br />
        Together With
        <span className="font-extrabold xl:text-6xl md:text-6xl text-4xl custom-stroke ml-4 font-sans">
          Standard & Influence
        </span>
      </h1>
      <style jsx>{`
        @media (max-width: 768px) {
          div[style] {
            background-image: url(${Resbg}) !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Hero;
