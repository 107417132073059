import React, { useState } from "react";
import "../../../Global.css";
import Location from "../../images/location.png";
import Call from "../../images/call.png";
import Mail from "../../images/mail.png";
import Swal from "sweetalert2";
import axios from "axios";

const InTouch = () => {
  const [formValues, setFormValues] = useState({
    your_name: "",
    email: "",
    phone: "",
    intrested_in: "",
    messages: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};

    if (!formValues.your_name) {
      newErrors.your_name = "User Name is required.";
    }

    if (!formValues.email) {
      newErrors.email = "Email Address is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      newErrors.email = "Email Address is invalid.";
    } else if (!formValues.email.endsWith(".com")) {
      newErrors.email = "Email must end with .com.";
    }
    if (!formValues.phone) {
      newErrors.phone = "Phone Number is required.";
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      newErrors.phone = "Phone Number must be 10 digits.";
    }

    if (!formValues.intrested_in) {
      newErrors.intrested_in = "Please select an option.";
    }

    // if (!formValues.messages) {
    //   newErrors.messages = "Message is required.";
    // }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const res = await axios.post(
          process.env.REACT_APP_JOB_DETAIL_URL + "/clients/",
          formValues,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 200 || res.status === 201) {
          Swal.fire({
            title: "Data has been submitted",
            icon: "success",
            toast: true,
            timer: 3000,
            position: "top-right",
          });

          const web3FormRes = await axios.post(
            "https://api.web3forms.com/submit",
            {
              access_key: process.env.REACT_APP_WEB3FORMS_API_KEY,
              ...formValues,
            }
          );

          if (web3FormRes.status === 200) {
            console.log("Web3Forms submission successful:", web3FormRes.data);
          } else {
            Swal.fire({
              title: "Mail not sent!",
              text: "There was an issue sending the mail. Please try again.",
              icon: "error",
              toast: true,
              timer: 3000,
              position: "top-right",
            });
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Submission failed!",
          text: error.response?.data?.message || "An error occurred.",
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
        });
      }
    }
  };

  return (
    <div
      className="xl:flex md:flex justify-center items-center"
      id="contact us"
    >
      <div className="bg-gradient-custom px-8 justify-center flex flex-col gap-3 rounded-md xl:w-1/2 md:w-1/2 min-h-[30rem] xl:py-[5rem] md:py-[5rem] py-6">
        <p className="text-white-100 text-4xl font-medium">
          <span className="bg-white-200 rounded-full pl-6">G</span>et in touch
        </p>
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <div>
            <div className="contact-border items-center p-2">
              <input
                type="text"
                name="your_name"
                value={formValues.your_name}
                onChange={handleChange}
                className="bg-transparent text-white-100 placeholder-white-100 px-2 py-1 rounded-md outline-none w-full focus:outline-none focus:bg-transparent"
                placeholder="User Name"
              />
            </div>
            {errors.your_name && (
              <span className="text-red-500">{errors.your_name}</span>
            )}
          </div>
          <div>
            <div className="contact-border items-center p-2">
              <input
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                className="bg-transparent text-white-100 placeholder-white-100 px-2 py-1 rounded-md outline-none w-full focus:outline-none focus:bg-transparent"
                placeholder="Email Address"
              />
            </div>
            {errors.email && (
              <span className="text-red-500">{errors.email}</span>
            )}
          </div>
          <div>
            <div className="contact-border items-center p-2">
              <input
                type="tel"
                name="phone"
                value={formValues.phone}
                onChange={handleChange}
                className="bg-transparent text-white-100 placeholder-white-100 px-2 py-1 rounded-md outline-none w-full focus:outline-none focus:bg-transparent"
                placeholder="Phone Number"
              />
            </div>
            {errors.phone && (
              <span className="text-red-500">{errors.phone}</span>
            )}
          </div>
          <div>
            <div className="contact-border items-center p-2">
              <select
                name="intrested_in"
                value={formValues.intrested_in}
                onChange={handleChange}
                className="bg-transparent text-white-100 placeholder-white-100 px-2 py-1 rounded-md outline-none w-full focus:outline-none focus:bg-transparent"
              >
                <option className="text-black-100" value="" disabled hidden>
                  Interested
                </option>
                <option style={{ color: "black" }} value="frontend">
                  Web developer
                </option>
                <option style={{ color: "black" }} value="mobileapp">
                  Mobile app developer
                </option>
                <option style={{ color: "black" }} value="backend">
                  Backend developer
                </option>
                <option style={{ color: "black" }} value="ui/ux">
                  UI/UX designer
                </option>
              </select>
            </div>
            {errors.intrested_in && (
              <span className="text-red-500">{errors.intrested_in}</span>
            )}
          </div>
          <div>
            <div className="contact-border items-center p-2">
              <textarea
                name="messages"
                value={formValues.messages}
                onChange={handleChange}
                className="bg-transparent text-white-100 placeholder-white-100 px-2 py-1 rounded-md outline-none w-full focus:outline-none focus:bg-transparent"
                placeholder="Message"
              />
            </div>
            {/* {errors.messages && (
              <span className="text-red-500">{errors.messages}</span>
            )} */}
          </div>
          <button
            type="submit"
            className="bg-white-100 rounded-md text-primary-100 h-[2.5rem] font-medium w-[7rem]"
          >
            Submit
          </button>
        </form>
      </div>
      <div className="xl:w-1/2 md:w-1/2">
        <iframe
          src="https://maps.google.com/maps?q=418-419 ashirwad infotech  gajera&t=&z=16&ie=UTF8&iwloc=&output=embed"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          className="h-[20rem] w-full"
          title="Google Map"
        ></iframe>
        <div className="flex flex-col gap-4 p-4">
          <div className="flex gap-3">
            <img
              src={Location}
              alt="field icon"
              className="w-[2.5rem] h-[2.5rem]"
            />
            <div className="flex flex-col gap-1">
              <div className="font-[500]">Address</div>
              <div>418-419, Laxmi Enclave-2, Katargam, Surat.</div>
            </div>
          </div>
          <div className="flex gap-3">
            <img
              src={Call}
              alt="field icon"
              className="w-[2.5rem] h-[2.5rem]"
            />
            <div className="flex flex-col gap-1">
              <div className="font-[500]">Phone</div>
              <div>+91 76002 73027</div>
            </div>
          </div>
          <div className="flex gap-3">
            <img
              src={Mail}
              alt="field icon"
              className="w-[2.5rem] h-[2.5rem]"
            />
            <div className="flex flex-col gap-1">
              <div className="font-[500]">Email</div>
              <div>info@ashirwadinfotech.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InTouch;
