import React, { useState } from "react";
import t1 from "../../images/t1.png";
import t2 from "../../images/t2.png";
import t3 from "../../images/t3.png";
import t4 from "../../images/t4.png";
import t5 from "../../images/t5.png";
import t6 from "../../images/t6.png";
import t7 from "../../images/t7.png";
import t8 from "../../images/t8.png";
import t9 from "../../images/t9.png";
import t10 from "../../images/t10.png";
import t11 from "../../images/t11.png";
import t12 from "../../images/t12.png";
import t13 from "../../images/t13.png";

const technologyData = [
  { id: 1, name: "HTML", category: "Frontend", img: t13 },
  { id: 2, name: "CSS", category: "Frontend", img: t10 },
  { id: 3, name: "Java Script", category: "Frontend", img: t3 },
  { id: 4, name: "React", category: "Frontend", img: t9 },
  { id: 5, name: "Flutter", category: "Backend", img: t6 },
  { id: 6, name: "Python", category: "Backend", img: t8 },
  { id: 7, name: "Android", category: "Frontend", img: t1 },
  { id: 8, name: "IOS", category: "Frontend", img: t11 },
  { id: 9, name: "React Native", category: "Frontend", img: t9 },
  { id: 10, name: "Django", category: "Backend", img: t12 },
  { id: 11, name: "NodeJs", category: "Backend", img: t7 },
  { id: 12, name: "Figma", category: "Design", img: t5 },
  { id: 13, name: "Illustrator", category: "Design", img: t4 },
  { id: 14, name: "Photoshop", category: "Design", img: t2 },
];

const categories = ["All", "Frontend", "Backend", "Design"];

const Technology = ({ servicesRef }) => {
  const [activeCategory, setActiveCategory] = useState("All");
  const filteredTechnologies =
    activeCategory === "All"
      ? technologyData
      : technologyData.filter((tech) => tech.category === activeCategory);

  return (
    <div
      className="flex flex-col max-w-screen-2xl gap-14 py-6"
      servicesRef={servicesRef}
    >
      <div className="flex flex-col md:flex-row xl:flex-row gap-4 justify-center md:justify-between items-center">
        <h2 className="text-4xl xl:text-5xl font-bold text-gray-100 px-4">
          <span className="bg-primary-300 text-gray-100 rounded-full px-6 pr-1">
            Te
          </span>
          chnology we work with
        </h2>

        <div className="flex flex-wrap gap-2 xl:gap-4 md:gap-4">
          {categories.map((category) => (
            <button
              key={category}
              className={`px-3 py-1 xl:px-4 xl:py-2 md:px-4 md:py-2 rounded-3xl transition duration-200 ease-in-out ${
                activeCategory === category
                  ? "bg-primary-300 text-primary-100 font-semibold"
                  : ""
              }`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center w-full">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 w-[90vw] gap-8">
          {filteredTechnologies.map((tech) => (
            <div key={tech.id} className="flex flex-col gap-3 items-center">
              <div className="flex flex-col items-center justify-center gradient-bg border-2 border-[#478eb8] border-dashed rounded-full w-[8rem] h-[8rem] transition duration-200 ease-in-out mx-auto">
                <img src={tech.img} alt={tech.name} />
              </div>
              <p className="text-center text-xl font-medium">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Technology;
