import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Navbar from "../global/Navbar";
import herobg from "../images/aibg.png";
import { GrAttachment } from "react-icons/gr";
// import Subscribe from "./contact/Subscribe";

const ApplyForJob = () => {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [fileName, setFileName] = useState("Upload your CV");

  const validationSchema = Yup.object().shape({
    your_name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com)$/,
        "Email must end with '.com'"
      )
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits.")
      .required("Phone number is required."),
    notice_period: Yup.string().required("Notice period is required."),
    experience: Yup.string().required("Experience is required."),
    current_salary: Yup.string().required("Current salary is required."),
    expected_salary: Yup.string().required("Expected salary is required."),
    meet_time: Yup.string().required("Meeting time is required."),
    resume: Yup.mixed()
      .required("Resume is required.")
      .test(
        "fileType",
        "Resume must be a PDF file.",
        (value) => value && value.type === "application/pdf"
      ),
  });

  const initialValues = {
    your_name: "",
    email: "",
    phone: "",
    notice_period: "",
    experience: "",
    current_salary: "",
    expected_salary: "",
    meet_time: "",
    resume: null,
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_JOB_DETAIL_URL}jobs/${id}/`
        );
        setJobDetails(response.data);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };
    fetchJobDetails();
  }, [id]);

  const submitForm = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("your_name", values.your_name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("notice_period", values.notice_period);
    formData.append("experience", values.experience);
    formData.append("expected_salary", values.expected_salary);
    formData.append("current_salary", values.current_salary);
    formData.append("resume", values.resume, values.resume.name);
    formData.append("meet_time", values.meet_time);

    try {
      await axios.post(
        `${process.env.REACT_APP_JOB_DETAIL_URL}applyjob/`,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );

      Swal.fire({
        title: "Data has been submitted",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      resetForm();

      const web3FormData = {
        access_key: process.env.REACT_APP_WEB3FORMS_API_KEY,
        your_name: values.your_name,
        email: values.email,
        phone: values.phone,
        notice_period: values.notice_period,
        experience: values.experience,
        expected_salary: values.expected_salary,
        current_salary: values.current_salary,
        meet_time: values.meet_time,
      };

      if (values.resume) {
        web3FormData.resume = values.resume;
      }

      const web3FormRes = await axios.post(
        "https://api.web3forms.com/submit",
        web3FormData
      );

      if (web3FormRes.status !== 200) {
        Swal.fire({
          title: "Mail not sent!",
          text: "There was an issue sending the mail. Please try again.",
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        title: "Submission failed!",
        text: "There was an issue submitting your application. Please try again.",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
      });
    }
  };

  return (
    <div
      className="flex flex-col gap-5 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${herobg})` }}
    >
      <div className="relative xl:bottom-[6.5rem] md:bottom-[6.5rem] bottom-[6.5rem] ">
        <Navbar />
      </div>
      <h1 className="flex text-white-100 justify-center items-center text-4xl xl:text-5xl md:text-5xl h-[10rem]">
        <span className="bg-white-200 text-white-100 rounded-full px-6 py-1 pr-1">
          A
        </span>
        pply For {jobDetails?.position_name} Job
      </h1>
      <div className="flex justify-center items-center min-h-screen bg-white-100 w-full xl:rounded-[5rem] md:rounded-[5rem] rounded-[3rem] shadow-lg py-6">
        <div className="max-w-7xl flex flex-col gap-20 w-[80%] py-10">
          <h3 className="text-center text-3xl font-semibold mx-auto w-full md:w-[35rem] text-gray-100">
            Want to apply for our Open Positions? We have a place for you to
            Shine!
          </h3>
          {jobDetails && (
            <div className="flex flex-col gap-2">
              <div className="font-medium text-xl ">Required skills:</div>
              <ul className="list-disc pl-8 flex flex-col gap-2">
                {jobDetails.company_requirement
                  .split(".")
                  .filter((requirement) => requirement.trim() !== "")
                  .map((requirement, index) => (
                    <li key={index}>{requirement.trim()}</li>
                  ))}
              </ul>
            </div>
          )}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitForm}
          >
            {({ setFieldValue, errors, touched }) => (
              <Form className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <Field
                      name="your_name"
                      placeholder="Enter Name"
                      className={`block w-full py-3 px-2 border-1 rounded-md bg-primary-200 ${
                        errors.your_name && touched.your_name
                          ? "border-red-500"
                          : "border-primary-100"
                      }`}
                    />
                    <ErrorMessage
                      name="your_name"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      className={`block w-full py-3 px-2 border-1 rounded-md bg-primary-200 ${
                        errors.email && touched.email
                          ? "border-red-500"
                          : "border-primary-100"
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <div
                      className={`flex items-center bg-primary-200 rounded-md ${
                        errors.phone && touched.phone
                          ? "border-red-500"
                          : "border-primary-100"
                      } border-1`}
                    >
                      <span className="px-3">+91</span>
                      <div
                        className={`h-[3.4rem] border-l ${
                          errors.phone && touched.phone
                            ? "border-red-500"
                            : "border-primary-100"
                        }`}
                      />
                      <Field
                        name="phone"
                        placeholder="Phone Number"
                        className={`block w-full py-3 px-2 rounded-md bg-primary-200 ${
                          errors.phone && touched.phone
                            ? "border-red-500"
                            : "border-primary-100"
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <Field
                      name="experience"
                      placeholder="Experience"
                      className={`block w-full py-3 px-2 border-1 rounded-md bg-primary-200 ${
                        errors.experience && touched.experience
                          ? "border-red-500"
                          : "border-primary-100"
                      }`}
                    />
                    <ErrorMessage
                      name="experience"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <Field
                      name="current_salary"
                      placeholder="Current Salary"
                      className={`block w-full py-3 px-2 border-1 rounded-md bg-primary-200 ${
                        errors.current_salary && touched.current_salary
                          ? "border-red-500"
                          : "border-primary-100"
                      }`}
                    />
                    <ErrorMessage
                      name="current_salary"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div>
                    <Field
                      name="expected_salary"
                      placeholder="Expected Salary"
                      className={`block w-full py-3 px-2 border-1 rounded-md bg-primary-200 ${
                        errors.expected_salary && touched.expected_salary
                          ? "border-red-500"
                          : "border-primary-100"
                      }`}
                    />
                    <ErrorMessage
                      name="expected_salary"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <Field
                      name="notice_period"
                      placeholder="Notice Period"
                      className={`block w-full py-3 px-2 border-1 border-solid rounded-md bg-primary-200 ${
                        errors.notice_period && touched.notice_period
                          ? "border-red-500"
                          : "border-primary-100"
                      }`}
                    />
                    <ErrorMessage
                      name="notice_period"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div>
                    <Field
                      name="meet_time"
                      as="select"
                      className={`block w-full py-3 px-2 border-1 rounded-md bg-primary-200 ${
                        errors.meet_time && touched.meet_time
                          ? "border-red-500"
                          : "border-primary-100"
                      }`}
                    >
                      <option value="" disabled>
                        If selected, when can we meet?
                      </option>
                      <option value="immediate">Immediate</option>
                      <option value="day">Day</option>
                      <option value="week">Week</option>
                      <option value="month">Month</option>
                    </Field>
                    <ErrorMessage
                      name="meet_time"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                  <div className="flex items-center justify-between border-1 border-primary-100 py-2 px-2 rounded bg-primary-200">
                    <label htmlFor="resume" className="text-gray-400">
                      {fileName}
                    </label>
                    <input
                      id="resume"
                      name="resume"
                      type="file"
                      className={`hidden`}
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        if (file) {
                          setFieldValue("resume", file);
                          setFileName(file.name);
                        }
                      }}
                      accept=".pdf"
                    />
                    <button
                      type="button"
                      className={`flex items-center gap-2 py-2 text-gray-400 px-4 border-1 rounded-md bg-white-100 ${
                        errors.resume && touched.resume
                          ? "border-red-500"
                          : "border-primary-300"
                      }`}
                      onClick={() => document.getElementById("resume").click()}
                    >
                      <span>Select File</span>
                      <GrAttachment />
                    </button>
                  </div>
                  <ErrorMessage
                    name="resume"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="flex">
                  <button
                    type="submit"
                    className="bg-primary-100 hover:bg-primary-400 text-white px-6 py-2 rounded-lg"
                  >
                    Apply Now
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {/* <Subscribe /> */}
        </div>
      </div>
    </div>
  );
};

export default ApplyForJob;
