import React from "react";
import OurFamily from "./OurFamily";
import InTouch from "./InTouch";
// import Subscribe from "./Subscribe";

const ContactIndex = React.forwardRef(({ inTouchRef }, ref) => {
  return (
    <div
      className="bg-white-100 xl:rounded-[5rem] md:rounded-[5rem] rounded-[3rem] shadow-md xl:py-24 md:py-24 p-10 py-20 flex flex-col items-center  "
      ref={ref}
    >
      <div className="2xl:w-[80vw] w-[90vw] flex flex-col gap-24">
        <OurFamily />
        <InTouch ref={inTouchRef} />
        {/* <Subscribe /> */}
      </div>
    </div>
  );
});

export default ContactIndex;
