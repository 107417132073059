import React from "react";
import "../../Global.css";
import { CareerData } from "../../data/Mockdata";

const Career = ({ careerRef }) => {
  return (
    <div
      className="flex flex-col gap-5 max-w-screen-2xl mx-auto w-[90vw] text-white justify-center min-h-[35rem] py-16  "
      ref={careerRef}
    >
      <div className="text-4xl xl:text-5xl font-bold leading-0">
        <span className="bg-white-200 rounded-full pl-4">W</span>hy choose
        Ashirwad
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-5 md:gap-5 gap-4 justify-center">
        {CareerData.map((item) => (
          <div
            key={item.id}
            className="flex items-center gap-4 xl:p-4 md:p-4 p-3 rounded-lg contact-border transition-transform duration-300 transform hover:scale-105"
          >
            <img src={item.img} alt={item.name} className="" />
            <div className="text-center font-medium text-lg">{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Career;
